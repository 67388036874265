import { useMemo, useCallback } from 'react';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createNewCategory } from '../../store/actions/category/createNewCategory';
import { selectSourceTree } from '../../store/actions/data/selectSourceTree';
import { TreePanes, togglePane } from '../../store/slices/pane';
import { newCategoryEnabledSelector } from '../../store/selectors/category/ui';
import { selectCurrentMarketCode } from '../../store/slices/intl';
import {
  resetTreeSorting,
  selectSearchPanelOpenForTree,
  selectSortedCatalogs,
  selectTreeCategoryRemovalInfo,
} from '../../store/slices/tree';
import { catalogConfigSelector } from '../../store/selectors/tree/data';
import { isMasterCatalog, STATUS } from '../../utils/catalog';
import { makeTreeId } from '../../utils/tree/ids';
import Pane from '../Pane';
import RemovalInfo from '../RemovalInfo';
import Tree from '../Tree';
import SearchPanel from './SearchPanel';
import TreeSelector from './TreeSelector/TreeSelector';
import { saveCategorySorting } from '../../store/actions/category/saveCategorySorting';
import { clearCategorySorting } from '../../store/actions/category/clearCategorySorting';
import { closeModal, openModal } from '../../store/actions/modal';
import { analyticsEvent } from '../../store/actions/analytics/analytics';
import {
  ANALYTICS_PROPERTIES_PANE,
  ANALYTICS_TREE,
} from '../../store/actions/analytics/analyticsCategories';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import checkmarkCircle from '@ingka/ssr-icon/paths/checkmark-circle';
import arrowCounterclockwise from '@ingka/ssr-icon/paths/arrow-counterclockwise';
import createNew from '@ingka/ssr-icon/paths/create-new';

import styles from './TreePane.module.scss';
import SearchToggler from './SearchToggler';
import ExpandToggler from './ExpandToggler';
import Header from '../Pane/Header';
import { Catalog } from '../../entities/Catalog';
import type { AppThunkDispatch, RootState } from '../../store';

const INVALID = 'INVALID';

type TreePaneProps = {
  active: boolean;
  catalog: Catalog;
  className: string;
  collapsed: boolean;
  openDetails: boolean;
  pane: TreePanes;
};

const TreePane = function TreePane({
  active,
  catalog,
  className,
  collapsed,
  openDetails,
  pane,
}: TreePaneProps) {
  const dispatch = useDispatch<AppThunkDispatch>();
  const sortedCatalogs = useSelector(selectSortedCatalogs);
  const market = useSelector(selectCurrentMarketCode);
  const sourceId = useMemo(() => catalog?.catalogId || INVALID, [catalog]);
  const tree = useMemo(() => makeTreeId(pane, sourceId), [pane, sourceId]);
  const { newCategory: showCreateNewCategory } = useSelector((state: RootState) =>
    catalogConfigSelector(state, sourceId),
  );
  const createNewCategoryDisabled = useSelector(
    (state: RootState) =>
      !(showCreateNewCategory && newCategoryEnabledSelector(state, tree)),
  );

  const categoryRemovalInfo = useSelector((state: RootState) =>
    selectTreeCategoryRemovalInfo(state, sourceId),
  );

  const createNewCategoryAction = useCallback(() => {
    dispatch(
      analyticsEvent({
        category: ANALYTICS_TREE,
        action: 'Create new category clicked',
        label: 'Create new category clicked',
      }),
    );
    dispatch(createNewCategory());
  }, [dispatch]);

  const selectSourceAction = useCallback(
    (catalogId: string) => {
      if (
        sortedCatalogs[sourceId] &&
        Object.keys(sortedCatalogs[sourceId]).length > 0
      ) {
        const dialogProps = {
          asPopup: true,
          labelCancel: 'Leave without saving',
          labelConfirm: 'Go back and continue editing',
          message: 'You will lose your unsaved changes if you leave without saving.',
          onCancel: () => {
            dispatch(resetTreeSorting({ catalogId: sourceId }));
            dispatch(selectSourceTree(pane, catalogId));
            dispatch(closeModal());
          },
          onClose: () => dispatch(closeModal()),
          onConfirm: () => dispatch(closeModal()),
          title: 'You have unsaved changes!',
        };

        dispatch(openModal('ConfirmDialog', dialogProps));
      } else {
        dispatch(selectSourceTree(pane, catalogId));
      }
    },
    [dispatch, pane, sourceId, sortedCatalogs],
  );

  const openDetailsAction = useCallback(() => {
    dispatch(
      analyticsEvent({
        category: ANALYTICS_PROPERTIES_PANE,
        action: 'Propeties pane open',
        label: 'Propeties pane open',
      }),
    );
    dispatch(togglePane(true));
  }, [dispatch]);
  const searchPanelOpen = useSelector((state: RootState) =>
    selectSearchPanelOpenForTree(state, tree),
  );
  const isDraftCatalog = useMemo(
    () => isMasterCatalog(catalog, STATUS.DRAFT),
    [catalog],
  );

  const treeKey = useMemo(
    () => `${market}-${pane}-${sourceId}`,
    [market, pane, sourceId],
  );

  const onSaveCategorySorting = useCallback(
    () => dispatch(saveCategorySorting(sourceId)),
    [dispatch, sourceId],
  );

  const onClearSorting = useCallback(
    () => dispatch(clearCategorySorting(sourceId)),
    [dispatch, sourceId],
  );

  const sortingButtons = useMemo(
    () =>
      sortedCatalogs[sourceId] && Object.keys(sortedCatalogs[sourceId]).length > 0
        ? [
            <Tooltip
              interactiveCustomTrigger
              key="save-sorting-tooltip"
              tooltipText="Save sorting"
            >
              <Button
                className={styles.treePaneButtonBlue}
                key="save-sort"
                ssrIcon={checkmarkCircle}
                iconOnly
                size="small"
                aria-label="Save sorting"
                type="tertiary"
                onClick={onSaveCategorySorting}
              />
            </Tooltip>,
            <Tooltip
              interactiveCustomTrigger
              key="undo-sorting-tooltip"
              tooltipText="Undo sorting"
            >
              <Button
                key="delete-sort"
                ssrIcon={arrowCounterclockwise}
                type="tertiary"
                iconOnly
                size="small"
                aria-label="Undo sorting"
                onClick={onClearSorting}
              />
              ,
            </Tooltip>,
          ]
        : [],
    [sortedCatalogs, onClearSorting, onSaveCategorySorting, sourceId],
  );

  return (
    <Pane id={pane} className={className} collapsed={collapsed}>
      <Header
        buttons={[
          ...sortingButtons,
          showCreateNewCategory && (
            <Tooltip
              interactiveCustomTrigger
              key="new-cateogory-tooltip"
              tooltipText="New category"
            >
              <Button
                key="new-category"
                onClick={createNewCategoryAction}
                size="small"
                iconOnly
                type="tertiary"
                ssrIcon={createNew}
                aria-label="New category"
                disabled={createNewCategoryDisabled}
              />
            </Tooltip>
          ),
          <SearchToggler key="search" tree={tree} />,
          <ExpandToggler key="size" pane={pane} checked={active} />,
          openDetails && <div className={styles.divider} key="divider" />,
          openDetails && (
            <Tooltip
              interactiveCustomTrigger
              key="show-details-tooltip"
              tooltipText="Show details"
            >
              <Button
                key="info"
                size="small"
                iconOnly
                type="tertiary"
                onClick={openDetailsAction}
                ssrIcon={informationCircle}
                aria-label="Show details pane"
              />
            </Tooltip>
          ),
        ]}
      >
        <TreeSelector
          pane={pane}
          key={`${market}-${pane}-${sourceId}`}
          id={`${market}-select-${pane}`}
          sourceId={sourceId}
          onChange={selectSourceAction}
        />
        {searchPanelOpen && <SearchPanel tree={tree} />}
      </Header>
      <div
        className={cx(
          styles.content,
          { [styles.dashed]: isDraftCatalog },
          className,
        )}
      >
        {Array.isArray(categoryRemovalInfo) && !!categoryRemovalInfo.length && (
          <RemovalInfo catalogId={sourceId} />
        )}
        <Tree key={treeKey} catalog={catalog} tree={tree} />
      </div>
    </Pane>
  );
};

export default TreePane;
