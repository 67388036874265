import {
  LIST_EXISTS,
  LIST_IMPORT,
  LIST_NOT_VALID_FOR_STRUCTURE,
} from '../../store/constants/importitems';
import {
  CATALOG_TYPE_AUTOMATED,
  CATALOG_TYPE_MASTER,
  CATALOG_TYPE_THEMES,
  CATALOG_TYPE_UNCATEGORIZED,
  CATALOG_TYPE_NOTVALIDFORSTRUCTURE,
  CATALOG_TYPE_MISSINGITEMS,
  CATALOG_TYPE_SERIES,
  CATALOG_TYPE_COLLECTION,
  CATALOG_TYPE_BOX,
  CATALOG_TYPE_ROOMS,
} from '../types/catalogTypes';
import AutomatedCatalogConfig from './config/AutomatedCatalogConfig';
import DefaultCatalogConfig from './config/DefaultCatalogConfig';
import ItemsListConfig from './config/ItemsListConfig';
import MasterCatalogConfig from './config/MasterCatalogConfig';
import NotValidForStructureCatalogConfig from './config/NotValidForStructureCatalogConfig';
import SeriesCatalogConfig from './config/SeriesCatalogConfig';
import CollectionsCatalogConfig from './config/CollectionsCatalogConfig';
import ThemesCatalogConfig from './config/ThemesCatalogConfig';
import UncategorizedCatalogConfig from './config/UncategorizedCatalogConfig';
import BoxCatalogConfig from './config/BoxCatalogConfig';
import RoomsCatalogConfig from './config/RoomsCatalogConfig';

const conf = {
  [CATALOG_TYPE_AUTOMATED]: AutomatedCatalogConfig,
  [CATALOG_TYPE_MASTER]: MasterCatalogConfig,
  [CATALOG_TYPE_NOTVALIDFORSTRUCTURE]: NotValidForStructureCatalogConfig,
  [CATALOG_TYPE_THEMES]: ThemesCatalogConfig,
  [CATALOG_TYPE_MISSINGITEMS]: UncategorizedCatalogConfig,
  [CATALOG_TYPE_UNCATEGORIZED]: UncategorizedCatalogConfig,
  [LIST_EXISTS]: ItemsListConfig,
  [LIST_IMPORT]: ItemsListConfig,
  [LIST_NOT_VALID_FOR_STRUCTURE]: ItemsListConfig,
  [CATALOG_TYPE_SERIES]: SeriesCatalogConfig,
  [CATALOG_TYPE_COLLECTION]: CollectionsCatalogConfig,
  [CATALOG_TYPE_BOX]: BoxCatalogConfig,
  [CATALOG_TYPE_ROOMS]: RoomsCatalogConfig,
};

export function createCatalogConfig({
  catalogId,
  catalogType,
  productsCatalogId,
  productsDraftCatalogId,
  isEditorForCurrentMarket,
  isGlobalMarket,
  isGlobalEditor,
  isBoxEditor,
}: {
  catalogId: string;
  catalogType: keyof typeof conf;
  productsCatalogId: string;
  productsDraftCatalogId: string;
  isEditorForCurrentMarket: boolean;
  isGlobalMarket: boolean;
  isGlobalEditor: boolean;
  isBoxEditor: boolean;
}) {
  const CatalogConfig = conf[catalogType] || DefaultCatalogConfig;
  return new CatalogConfig({
    catalogId,
    productsCatalogId,
    productsDraftCatalogId,
    isEditorForCurrentMarket,
    isBoxEditor,
    isGlobalMarket,
    isGlobalEditor,
  });
}
