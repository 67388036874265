import { Key, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventDataNode } from 'rc-tree/es/interface';
import { selectMultiple } from '../../store/actions/nodes/selectMultiple';
import { togglePane } from '../../store/actions/pane';
import { expandTreeNode } from '../../store/actions/tree/expand';
import {
  selectSearchFocusIdForCatalog,
  selectTreeDataByCatalogId,
} from '../../store/selectors/tree';
import { catalogConfigSelector } from '../../store/selectors/tree/data';
import { selectedTreeNodeKeysByTreeIdSelector } from '../../store/selectors/tree/node';
import { getPathById } from '../../utils/tree/treeNode';
import {
  NODE_TYPE_CATALOG,
  NODE_TYPE_CATEGORY,
  NODE_TYPE_ITEM,
  NODE_TYPE_MULTI_SELECT,
  NODE_TYPE_SUGGESTION,
} from '../../utils/types/nodeTypes';
import Loading from '../Loading';
import CommonTree from './CommonTree';
import { useTreeShortcutKeys } from './useTreeShortcutKeys';
import { useOnMount } from '../../hooks/useOnMount';
import { fetchTree } from '../../store/actions/tree/fetchTree';
import { selectSuggestionFocusIdForCatalog } from '../../store/slices/tree';
import type { AppDispatch, RootState } from '../../store';
import { Catalog } from '../../entities/Catalog';
import { selectIsGlobalMarket } from '../../store/slices/intl';
import { OnSelectEvent } from './treeTypes';
import { NodeBase } from '../../entities/Node';

const Tree = ({ catalog, tree }: { catalog: Catalog; tree: string }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [scrollToKey, setScrollToKey] = useState<number | string | null>(null);
  const isGlobalMarket = useSelector(selectIsGlobalMarket);
  const treeData = useSelector((state: RootState) =>
    selectTreeDataByCatalogId(state, catalog.catalogId),
  );

  const { indentTree } = useSelector<RootState, { indentTree: boolean }>((state) =>
    catalogConfigSelector(state, catalog.catalogId),
  );
  const selectedKeys = useSelector<RootState>((state) =>
    selectedTreeNodeKeysByTreeIdSelector(state, catalog.catalogId),
  ) as string[];

  const searchFocusId = useSelector<RootState>((state) =>
    selectSearchFocusIdForCatalog(state, catalog.catalogId),
  ) as string;
  const suggestionFocusId = useSelector<RootState>((state) =>
    selectSuggestionFocusIdForCatalog(state, catalog.catalogId),
  ) as string;

  const selectTreeNode = useCallback(
    (keys: Key[], event: OnSelectEvent) => {
      if (event.node.type === NODE_TYPE_MULTI_SELECT) {
        return null;
      }

      const nodeItem = { node: { ...event.node }, path: getPathById(event.node.id) };
      const { shiftKey, ctrlKey, metaKey } = event.nativeEvent || {};

      return dispatch(
        selectMultiple(tree, nodeItem, { shiftKey, ctrlKey, metaKey }),
      );
    },
    [dispatch, tree],
  );

  const onDoubleClick = useCallback(
    (_: unknown, node: { type: string }) => {
      if (
        [
          NODE_TYPE_CATALOG,
          NODE_TYPE_CATEGORY,
          NODE_TYPE_ITEM,
          NODE_TYPE_SUGGESTION,
        ].includes(node.type)
      ) {
        dispatch(togglePane(true));
      }
    },
    [dispatch],
  );
  const onExpand = useCallback(
    (treeNode: {
      node: EventDataNode<NodeBase>;
      expanded: boolean;
      nativeEvent: MouseEvent;
    }) => {
      dispatch(expandTreeNode(tree, treeNode));
    },
    [dispatch, tree],
  );

  const [onKeyDown, onActiveChange] = useTreeShortcutKeys(catalog.catalogId);

  const keyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    // @ts-expect-error useTreeShortcutKeys not typed
    onKeyDown(event);
  };

  const onActive = (key: Key) => {
    // @ts-expect-error useTreeShortcutKeys not typed
    onActiveChange(key);
  };

  useOnMount(() => {
    dispatch(
      fetchTree({
        tree,
        catalog,
        isGlobalMarket,
      }),
    );
  });

  useEffect(() => setScrollToKey(searchFocusId), [searchFocusId]);
  useEffect(() => setScrollToKey(suggestionFocusId), [suggestionFocusId]);

  return treeData.length ? (
    <CommonTree
      autoExpandParent={false}
      onActiveChange={onActive}
      indentTree={indentTree}
      onDoubleClick={onDoubleClick}
      onExpand={onExpand}
      onKeyDown={keyDown}
      onSelect={selectTreeNode}
      scrollToKey={scrollToKey}
      selectedKeys={selectedKeys}
      treeNodes={treeData}
    />
  ) : (
    <Loading text="Catalogue is loading, please wait..." />
  );
};

export default Tree;
