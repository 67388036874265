import DefaultCatalogConfig, { CatalogConfigProps } from './DefaultCatalogConfig';

export default class ThemesCatalogConfig extends DefaultCatalogConfig {
  constructor(props: CatalogConfigProps) {
    super(props);

    this.categoryNameEditable = this.editable;
    this.allowHoverActions = props.isEditorForCurrentMarket;
    this.allowItemOperations =
      props.isGlobalMarket && props.isEditorForCurrentMarket;
    // RC-3183
    this.allowRanking = props.isGlobalMarket || props.isEditorForCurrentMarket;
    this.moveDisabled = false;
    this.newCategory = this.editable;

    this.catalog.translation = props.isEditorForCurrentMarket;
  }
}
