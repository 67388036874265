export const ROLE_GLOBAL = 'RC_MARKET_ZZ';

export function makeRetailUnitRoleName(retailUnit) {
  return `RC_MARKET_${retailUnit.toUpperCase()}`;
}

export function makeFilterRetailUnitRoleName(retailUnit) {
  return `RC_FILTERS_${retailUnit.toUpperCase()}`;
}

export function makeBoxRetailUnitRoleName(retailUnit) {
  return `RC_BOX_${retailUnit.toUpperCase()}`;
}
