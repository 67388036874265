import DefaultCatalogConfig, { CatalogConfigProps } from './DefaultCatalogConfig';

export default class RoomsCatalogConfig extends DefaultCatalogConfig {
  constructor(props: CatalogConfigProps) {
    super(props);
    this.editable = props.isEditorForCurrentMarket;

    this.category.autoCategorisable = false;
    this.category.rename = props.isEditorForCurrentMarket;
    this.category.delete = props.isGlobalMarket && props.isEditorForCurrentMarket;
    this.category.comment = false;
    this.category.import = false;
    this.category.publish = props.isEditorForCurrentMarket;
    this.category.translation = props.isEditorForCurrentMarket;
    this.category.levelRestriction = false;
    this.category.images = props.isEditorForCurrentMarket;

    this.catalog.availableForMarkets =
      props.isGlobalMarket && props.isEditorForCurrentMarket;
    this.catalog.translation =
      !props.isGlobalMarket && props.isEditorForCurrentMarket;

    this.categoryNameEditable = props.isEditorForCurrentMarket;
    this.allowRanking = props.isEditorForCurrentMarket;
    this.newCategory = props.isEditorForCurrentMarket;
    this.allowCutCategory = false;
    this.allowItemOperations = props.isEditorForCurrentMarket;
    this.allowCopyCategory = props.isEditorForCurrentMarket;
    this.moveDisabled = false;
    this.allowHoverActions = props.isEditorForCurrentMarket;
  }
}
