import { getUnfoldedNodeIds } from '../../../components/Tree/helpers/folding';
import { createCatalogConfig } from '../../../utils/tree/factory';
import { makeTreeId } from '../../../utils/tree/ids';
import { PANE_SOURCETREE, PANE_TARGETTREE } from '../../constants/panes';
import { selectTreeDataByCatalogId } from '.';
import { selectCatalog } from '../catalog';
import {
  masterCatalogIdSelector,
  masterDraftCatalogIdSelector,
} from '../catalog/ids';
import { selectPanesCatalog } from '../pane/data';

import {
  createReferenceEqualSelector,
  createShallowEqualResultSelector,
  getIdFromArgument,
} from '../utils';
import { selectIsGlobalMarket } from '../../slices/intl';
import {
  selectHasGlobalEditorRole,
  selectIsBoxEditorForCurrentMarket,
  selectIsEditorForCurrentMarket,
} from '../roles';

export const selectMasterTreeData = createReferenceEqualSelector(
  masterCatalogIdSelector,
  (state) => state,
  (catalogId, state) => selectTreeDataByCatalogId(state, catalogId),
);

export const selectTreeState = createShallowEqualResultSelector(
  selectTreeDataByCatalogId,
  (treeData) => getUnfoldedNodeIds(treeData),
);

export const treeIdByCatalogIdSelector = createReferenceEqualSelector(
  selectPanesCatalog,
  getIdFromArgument,
  (panesCatalog, catalogId) => {
    if (panesCatalog[PANE_SOURCETREE]?.catalogId === catalogId) {
      return makeTreeId(PANE_SOURCETREE, catalogId);
    }
    if (panesCatalog[PANE_TARGETTREE]?.catalogId === catalogId) {
      return makeTreeId(PANE_TARGETTREE, catalogId);
    }
    return undefined;
  },
);

export const catalogConfigSelector = createReferenceEqualSelector(
  selectCatalog,
  masterCatalogIdSelector,
  masterDraftCatalogIdSelector,
  selectIsEditorForCurrentMarket,
  selectIsGlobalMarket,
  selectHasGlobalEditorRole,
  selectIsBoxEditorForCurrentMarket,
  (
    catalog,
    productsCatalogId,
    productsDraftCatalogId,
    isEditorForCurrentMarket,
    isGlobalMarket,
    isGlobalEditor,
    isBoxEditor,
  ) =>
    createCatalogConfig({
      catalogId: catalog?.catalogId,
      catalogType: catalog?.catalogType,
      productsCatalogId,
      productsDraftCatalogId,
      isEditorForCurrentMarket,
      isGlobalMarket,
      isGlobalEditor,
      isBoxEditor,
    }),
);
