import { ReadOnlyArray } from '../../array/ReadOnlyArray';
import DefaultCatalogConfig from './DefaultCatalogConfig';

export default class ItemsListConfig extends DefaultCatalogConfig {
  allowedSources = ReadOnlyArray.Empty;

  canPaste = () => false;

  indentTree = false;
}
