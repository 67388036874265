import { ReadOnlyArray } from '../../array/ReadOnlyArray';
import DefaultCatalogConfig, { CatalogConfigProps } from './DefaultCatalogConfig';

export default class AutomatedCatalogConfig extends DefaultCatalogConfig {
  constructor(props: CatalogConfigProps) {
    super(props);
    this.allowedSources = ReadOnlyArray.Empty;
    this.editable = false;
    this.indentTree = false;
    this.allowHoverActions = props.isGlobalEditor;
    this.allowRanking = props.isGlobalEditor;

    this.category = {
      levelRestriction: false,
      levelEditable: [0],
      hide: false,
      rename: false,
      translation: props.isEditorForCurrentMarket,
      comment: true,
      delete: false,
      publish: false,
      autoCategorisable: false,
      import: false,
      categoryType: false,
      images: false,
    };
  }
}
