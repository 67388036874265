import { openModal } from '../../../../store/actions/modal';
import { openDialogRemoveItems } from '../../../../store/actions/modal/removeItems';
import { nodeMenuCopy } from '../../../../store/actions/nodeMenu/copy';
import { nodeMenuCut } from '../../../../store/actions/nodeMenu/cut';
import { markForCategorization } from '../../../../store/actions/nodeMenu/markForCategorization';

export function getMultiselectActions(_, { dispatch, nodeItem, tree }) {
  const { isInSuggestion, isItems, isUncategorized } = nodeItem.node;
  const nodeActions = {};
  if (isInSuggestion) {
    nodeActions.openAlterSuggestionsDialog = () =>
      dispatch(openModal('AlterSuggestionsDialog', { tree, nodeItem }));
  } else if (isUncategorized) {
    nodeActions.nodeMenuCut = () => dispatch(markForCategorization(tree, nodeItem));
  } else {
    nodeActions.nodeMenuCopy = () => dispatch(nodeMenuCopy(tree, nodeItem));
    nodeActions.nodeMenuCut = () => dispatch(nodeMenuCut(tree, nodeItem));
    if (isItems) {
      nodeActions.openDialogRemoveItem = () =>
        dispatch(openDialogRemoveItems(tree, nodeItem));
    }
  }
  return nodeActions;
}
