import DefaultCatalogConfig, { CatalogConfigProps } from './DefaultCatalogConfig';

export default class CollectionsCatalogConfig extends DefaultCatalogConfig {
  constructor(props: CatalogConfigProps) {
    super(props);

    this.categoryNameEditable = this.editable;
    this.newCategory = this.editable;
    this.allowHoverActions = props.isEditorForCurrentMarket;
    this.allowItemOperations =
      props.isGlobalMarket && props.isEditorForCurrentMarket;
    this.allowCopyCategory = this.editable;
    this.allowRanking = !props.isGlobalMarket && props.isEditorForCurrentMarket;
    this.moveDisabled = false;

    this.catalog.translation =
      !props.isGlobalMarket && props.isEditorForCurrentMarket;
  }
}
